import React from 'react'

const Line = () => {
  return (
    <div
      style={{ backgroundColor: "#E8303A", width: "8%", height: "6px" }}
    ></div>
  );
}

export default Line