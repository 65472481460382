import React from 'react';

const ServiceDetails = (props) => {
  return (
    <div className="service-details">
      <p>{props.details}</p>
    </div>
  );
}

export default ServiceDetails